import $ from 'jquery';
import 'jquery.scrollto';

window.$ = $;
window.jQuery = $;


function updateSidebar() {
  const tc = $('.top_container').height();
  const w = $(window).height();
  const spaceLeft = w - tc;

  const sObject = $('.navbar-sidebar');
  const sHeight = sObject.height();
  const numLi = sObject.children().length;

  if (numLi > 1 && sHeight > 50) {
    if (spaceLeft < sHeight) {
      // not enough space, force horizontal
      $('#force-target').removeClass('sidebar-vertical');
      $('#force-target').addClass('sidebar-horizontal');
      // $('#sidebar.collapse').css('display', 'none');
    } else {
      // there is enough space
      $('#force-target').removeClass('sidebar-vertical');
      $('#force-target').removeClass('sidebar-horizontal');
      // $('#sidebar.collapse').css('display', 'block');
    }
  } else if (spaceLeft > sHeight) {
    $('#force-target').removeClass('sidebar-horizontal');
  }
}

function htmlbodyHeightUpdate() {
  const height3 = $(window).height();
  const height1 = $('.nav').height() + 50;
  const height2 = $('.main').height();

  if (height2 > height3) {
    $('html').height(Math.max(height1, height3, height2) + 10);
    $('body').height(Math.max(height1, height3, height2) + 10);
  } else {
    $('html').height(Math.max(height1, height3, height2));
    $('body').height(Math.max(height1, height3, height2));
  }
}

(() => {
  let doit;
  htmlbodyHeightUpdate();
  updateSidebar();

  $(window).resize(() => {
    // htmlbodyHeightUpdate()
    clearTimeout(doit);
    doit = setTimeout(updateSidebar, 500);
    // update_sidebar();
  });
  $(window).scroll(() => {
    // update_sidebar();
    // height2 = $('.main').height()
    // htmlbodyHeightUpdate()
  });
  // $('#sidebar').on('hidden.bs.collapse', function () {
  //   alert('hidden');
  // });

  // $('#sidebar').on('shown.bs.collapse', function () {
  //   alert('shown');
  // });
})();
